<script>
    import Swiper from "@/plugins/swiper-bundle";
    import TheSvgIcon from "@frontend/the-svg-icon";

    export default {
        name: "TheSlider",
        components: { TheSvgIcon },
        props: {
            numberSlides: {
                default: 1,
            },
            options: {
                type: Object,
            },
            nameSlider: {
                required: true,
                type: String,
            },
            isPagination: {
                default: false,
                type: Boolean,
            },
            isBullets: {
                default: false,
                type: Boolean,
            },
            bulletsOptions: {
                type: Object,
            },
            isArrow: {
                default: true,
                type: Boolean,
            },
            arrowPosition: {
                type: String,
                default: "center",
            },
            list: [Array, Object],
            eventsEmitting: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            const options = {};
            if (this.isBullets) {
                let circleHtml = "";
                if (this.bulletsOptions.type === "circle") {
                    circleHtml = `<div class="circle">
                            <div class="circle__mask on-left">
                                <div class="circle__inner"></div>
                            </div>
                            <div class="circle__mask on-right">
                                <div class="circle__inner"></div>
                            </div>
                            <div class="circle__holder"></div>
                        </div>`;
                }
                options.pagination = {
                    el: `.${this.nameSlider}-dots`,
                    clickable:
                        this.bulletsOptions.clickable !== null &&
                        this.bulletsOptions.clickable !== undefined
                            ? this.bulletsOptions.clickable
                            : true,
                    dynamicBullets: this.bulletsOptions.dynamicBullets,
                    dynamicMainBullets: this.bulletsOptions.dynamicMainBullets || 1,
                    renderBullet: (index, className) => {
                        return `
                    <span class="${className} c-slider__${this.bulletsOptionsData.type} outline" style="animation-duration: ${this.slideChangeTimer}ms">${circleHtml}</span>`;
                    },
                };
            }
            if (this.isPagination) {
                options.scrollbar = {
                    el: `.${this.nameSlider}-scrollbar`,
                    hide: false,
                    draggable: true,
                };
            }
            return {
                currentSlider: null,
                swiperOption: {
                    ...options,
                    centredSlides: true,
                    watchOverflow: true,
                    slidesPerView: this.numberSlides,
                    navigation: {
                        nextEl: `.${this.nameSlider}-next`,
                        prevEl: `.${this.nameSlider}-prev`,
                    },
                    mousewheel: {
                        forceToAxis: true,
                    },
                    ...this.options,
                },
                bulletsOptionsData: {
                    animated: false,
                    type: "dots",
                    ...this.bulletsOptions,
                },
                slideChangeTimer: 0,
            };
        },
        computed: {
            namePrevArrow() {
                return [`${this.nameSlider}-prev`, `c-slider__arrow--${this.arrowPosition}`];
            },
            nameNextArrow() {
                return [`${this.nameSlider}-next`, `c-slider__arrow--${this.arrowPosition}`];
            },
            namePagination() {
                return `${this.nameSlider}-scrollbar`;
            },
            nameDots() {
                return `${this.nameSlider}-dots ${this.$root.$_name}-dots`;
            },
            classNameSlider() {
                return `js-${this.nameSlider}`;
            },
            numberSlotSliderItem() {
                return this.$slots["swiper-slide"] ? this.$slots["swiper-slide"].length : 0;
            },
            computeSlidesCount() {
                return this.numberSlotSliderItem || this.list?.length;
            },
            showPagination() {
                return this.isPagination && this.computeSlidesCount > this.numberSlides;
            },
            showBullets() {
                return this.isBullets && this.computeSlidesCount > this.numberSlides;
            },
            showBottomWrapper() {
                return (
                    this.showBullets ||
                    (this.isArrow && this.computeSlidesCount > this.numberSlides)
                );
            },
        },
        watch: {
            list: {
                handler() {
                    setTimeout(() => {
                        this.currentSlider.update();
                        this.currentSlider.scrollbar.updateSize();
                    }, 100);
                },
            },
        },
        mounted() {
            this.slideChangeTimer = this.swiperOption.autoplay?.delay || 0;
            this.$nextTick(() => {
                this.currentSlider = new Swiper(`.${this.classNameSlider}`, {
                    init: false,
                    ...this.swiperOption,
                });
                /**
                 * Callback после инициализации слайдера
                 */
                this.currentSlider.on("init", (slider) => {
                    if (this.eventsEmitting) {
                        this.$emit("on-init-slider");
                    }
                    if (this.isBullets && this.bulletsOptionsData.animated) {
                        this.initDots(slider);
                    }
                });

                this.currentSlider.init();

                if (this.eventsEmitting) {
                    /**
                     * Callback после переключения слайдов
                     */
                    this.currentSlider.on("slideChange", (slider) => {
                        this.$emit("on-slide-changed", slider.activeIndex);
                        if (this.isBullets && this.bulletsOptionsData.animated) {
                            this.animateDots(slider);
                        }
                    });
                }
                setTimeout(() => {
                    if (this.currentSlider.$wrapperEl) {
                        this.$emit("on-update");
                        this.currentSlider.updateSlides();
                    }
                }, 700);
            });
        },
        methods: {
            /**
             * Инициализация анимированных буллетов пагинации
             * @param { Object } slider - текущий слайдер
             */
            initDots(slider) {
                const bullets = slider.pagination.bullets;
                bullets[0].classList.add("c-slider-animated");
            },
            /**
             * Анимация буллетов пагинации при изменении слайдов
             * @param { Object } slider - текущий слайдер
             */
            animateDots(slider) {
                const bullets = slider.pagination.bullets;
                bullets[slider.activeIndex].classList.add("c-slider-animated");
                bullets[slider.previousIndex].classList.remove("c-slider-animated");
            },
        },
    };
</script>

<template>
    <div class="c-slider">
        <div class="c-slider__swiper swiper-container" :class="classNameSlider">
            <div class="swiper-wrapper">
                <slot name="swiper-slide"></slot>
            </div>
        </div>

        <button
            v-if="isArrow && arrowPosition === 'center'"
            class="swiper-button-prev c-slider__arrow c-slider__arrow--prev outline 111"
            slot="button-prev"
            :class="namePrevArrow"
        >
            <the-svg-icon name="arrow" :size-w="24"></the-svg-icon>
        </button>
        <button
            v-if="isArrow && arrowPosition === 'center'"
            class="swiper-button-next c-slider__arrow c-slider__arrow--next outline 222"
            slot="button-next"
            :class="nameNextArrow"
        >
            <the-svg-icon name="arrow" :size-w="24"></the-svg-icon>
        </button>

        <div
            v-show="showPagination"
            class="swiper-scrollbar c-slider__scrollbar"
            :class="namePagination"
        ></div>

        <div
            v-show="showBottomWrapper"
            class="c-slider__bottom-wrapper"
            :class="[`${nameSlider}-bottom-wrapper`]"
        >
            <button
                v-if="isArrow && arrowPosition === 'bottom'"
                class="swiper-button-prev c-slider__arrow c-slider__arrow--prev outline"
                slot="button-prev"
                :class="namePrevArrow"
            >
                <the-svg-icon name="arrow" :size-w="24"></the-svg-icon>
            </button>

            <div
                v-show="showBullets"
                class="swiper-dots c-slider__bullets"
                :class="nameDots"
            ></div>

            <button
                v-if="isArrow && arrowPosition === 'bottom'"
                class="swiper-button-next c-slider__arrow c-slider__arrow--next outline"
                slot="button-next"
                :class="nameNextArrow"
            >
                <the-svg-icon name="arrow" :size-w="24"></the-svg-icon>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    //@import "./swiper";
    .swiper-pagination-lock {
        display: none !important;
    }

    .swiper-button-lock {
        display: none !important;
    }

    .c-slider {
        position: relative;

        &.row-blog-slider {
            .swiper-container {
                overflow: visible;

                @include bp($bp-tablet-sm) {
                    overflow: hidden;
                }
            }
        }
    }

    .c-slider__swiper {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 12px;
        }
    }

    .c-slider__scrollbar {
        width: 100%;
        height: 2px;
        background-color: #c4cdd5;

        .swiper-scrollbar-drag {
            background-color: #182028;
        }
    }

    .c-slider__bottom-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
    }

    .c-slider__bullets {
        white-space: nowrap;
        text-align: center;
    }

    .xd-design-dots {
        .c-slider__line {
            position: relative;
            width: 35px;
            height: 3px;
            margin: 0 8px;
            background-color: #c4cdd5;
            border-radius: 0;
            outline: none;
            opacity: 1;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
                width: 100%;
                background-color: var(--primary);
                transform: scaleX(0);
                transform-origin: left;
                will-change: transform;
            }

            @include bp($bp-desktop-sm) {
                height: 5px;
            }
        }

        .c-slider-animated {
            &::after {
                animation: dot-animation forwards linear;
                animation-duration: inherit;
            }
        }

        .c-slider-paused {
            &::after {
                animation-play-state: paused;
            }
        }
    }

    @mixin animation($animate...) {
        $max: length($animate);
        $animations: "";
        @for $i from 1 through $max {
            $animations: #{$animations + nth($animate, $i)};
            @if $i < $max {
                $animations: #{$animations + ", "};
            }
        }
        animation: $animations;
    }

    .picooc-dots,
    .mi-ku-dots,
    .chipolo-dots {
        .c-slider__circle {
            position: relative;
            @include box(6px);
            margin: 0 8px;
            background-color: var(--primary--dark);
            border-radius: 50%;
            outline: none;
            opacity: 1;

            @include bp($bp-desktop-sm) {
                @include box(10px);
            }
        }

        .c-slider-animated {
            .on-left {
                .circle__inner {
                    @include animation(circle-left 8s linear 0s 1);
                }
            }

            .on-right {
                .circle__inner {
                    @include animation(circle-right 8s linear 0s 1);
                }
            }
        }

        .c-slider-paused {
            .circle__inner {
                .on-left & {
                    animation-play-state: paused;
                }

                .on-right & {
                    animation-play-state: paused;
                }
            }
        }

        .c-slider__dot {
            min-width: 10px;
            min-height: 10px;
            transition: 0.2s transform, 0.2s left;
        }
    }

    .c-slider__bullets {
        .c-slider__dot.swiper-pagination-bullet-active {
            transform: scale(1);
        }
    }

    .c-slider__dot {
        width: 10px;
        height: 10px;
        margin: 0 8px;
        background-color: var(--slider-dot);
        border-radius: 50%;
        transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
        opacity: 1;
        outline: none;

        &.swiper-pagination-bullet-active {
            border-radius: 50%;
            background-color: var(--slider-dot--active);
            transform: scale(1.2);
        }
    }

    .c-slider__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        @include box(40px);
        border-radius: 50%;
        background-color: var(--slider-arrow-bg);
        margin-top: 0;
        transform: translateY(-50%);
        outline: none;
        svg {
            fill: #fff;
        }

        &:after {
            display: none;
        }

        &.swiper-button-disabled {
            opacity: 0 !important;
        }
    }

    .c-slider__arrow--next {
        svg {
            transform: rotate(180deg);
        }
    }

    .c-slider__arrow--bottom {
        background-color: transparent;
        transition: 0.3s ease-in-out all;
        svg {
            fill: #182028;
        }
        @include bp($bp-desktop-sm) {
            position: relative;
            display: flex;
            width: 32px;
            height: 32px;
            transform: none;
        }
        &[aria-disabled="true"],
        &.swiper-button-disabled {
            opacity: 0.3 !important;
        }
        &.c-slider__arrow--prev {
            left: auto;
            margin-right: 30px;
        }
        &.c-slider__arrow--next {
            right: auto;
            margin-left: 30px;
        }
    }

    .swiper-scrollbar-drag {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            margin: -6px;
            cursor: grab;
        }
    }

    @keyframes dot-animation {
        0% {
            transform: scaleX(0);
        }
        100% {
            transform: scaleX(1);
        }
    }

    $size_sm: 12px;
    $size_lg: 22px;
    $circleColor: #fff;
    $innerColor: var(--primary--light);
    $holderColor: #fff;

    .circle-size {
        @include box($size_sm);
        border-radius: 50%;

        @include bp($bp-desktop-sm) {
            @include box($size_lg);
        }
    }

    .clip-show-left {
        clip: rect(0, $size_sm/2, $size_sm, 0);

        @include bp($bp-desktop-sm) {
            clip: rect(0, $size_lg/2, $size_lg, 0);
        }
    }

    .clip-show-right {
        clip: rect(0, $size_sm, $size_sm, $size_sm/2);

        @include bp($bp-desktop-sm) {
            clip: rect(0, $size_lg, $size_lg, $size_lg/2);
        }
    }

    .circle {
        @extend .circle-size;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $circleColor;
        z-index: -1;
    }

    .circle__mask {
        @extend .circle-size;
        position: absolute;
        left: 0;
        top: 0;

        &.on-left {
            @extend .clip-show-left;
        }

        &.on-right {
            @extend .clip-show-right;
        }
    }

    .circle__inner {
        @extend .circle-size;
        position: absolute;
        @include box(100%);
        background: $innerColor;

        .on-left & {
            @extend .clip-show-right;
        }

        .on-right & {
            @extend .clip-show-left;
        }
    }

    .circle__holder {
        @include box(10px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: $holderColor;

        @include bp($bp-desktop-sm) {
            @include box(20px);
        }
    }

    @keyframes circle-left {
        0% {
            transform: rotate(0);
        }
        25% {
            transform: rotate(0);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes circle-right {
        0% {
            transform: rotate(0);
        }
        25% {
            transform: rotate(180deg);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
